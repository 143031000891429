<script setup>
import { transl } from "@/composables/useTranslation";
import { useAccount } from "@/composables/useAccount";
import { createRequest, lookupRequest } from "~/composables/useRequests";
import { toPhoneModel } from "~/composables/usePhone";

const { activeAccount } = useAccount();

const props = defineProps({
  course: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(["registered"]);

const loading = ref(false);

const formModel = reactive({
  userName: "",
  userPhone: {},
  userRegion: "",
  userLocation: "",
  userAgreements: false
});

const formHtmlEl = ref(null);

async function submitForm() {
  if (!formHtmlEl.value.validate())
    return toastError("Texts:Please fill the form with valid data", { autoclose: false });

  const foundRequest = await lookupRequest({
    phone: toPhoneModel(formModel.userPhone)?.phone,
    email: formModel.userEmail?.toLowerCase(),
  });

  if (foundRequest) {
    return toastInfo("Вы уже подавали заявку сегодня", { autoclose: false });
  }

  let requestDto = {
    request_user_name: formModel.userName,
    request_user_phone: toPhoneModel(formModel.userPhone),
    request_user_email: formModel.userEmail?.toLowerCase(),
    request_user_region: {
      location_place_full: formModel.userLocation || "",
    },
    // request_user_password: userPassword.value,
    // request_user_progress: readPropFromLocalStorage("userProgress"),
    request_account_id: activeAccount.value._id,
    request_source: new URL(window.location).hostname,
  };

  // if user selected course before registr
  if (props.course) {
    requestDto.request_course_id = props.course?._id;
    requestDto.request_course_name = props.course?.course_title;
  }

  //sending loader
  loading.value = true;

  const success = await createRequest({ request: requestDto });
  if (success) {
    toastSuccess("Texts:You has successfully leave your registration apply", {
      autoclose: false,
    });
    // clean form
    Object.assign(formModel, {
      userName: "",
      userPhone: {},
      userRegion: "",
      userLocation: "",
      userAgreements: false
    });
    // savePropToLocalStorage("userPogress", []);
  }

  // await sendNotifRequestCreate({ request: requestDto });

  //sending loader
  loading.value = false;

  //local event
  emit("registered");
}
</script>
<template>
  <div class="request-wraper">
    <div class="left">
      <div class="title">{{ transl("Landing:Leave an application") }}</div>
      <div class="description mt-3">
        {{
          transl(
            "Landing:We bind to you soon and send materials Course convenient for you in a way"
          )
        }}
      </div>

      <!-- form start -->
      <v-form ref="formHtmlEl">
        <v-text-field
          v-model="formModel.userName"
          class="form-field-el mt-4"
          :rules="[rules.required]"
          :label="transl('Texts:Name')"
        ></v-text-field>
        <v-phone-field
          v-model="formModel.userPhone"
          :defaultCountryIso="activeAccount?.account_country_iso"
          :label="
            formModel.userPhone.country
              ? transl(`Country:${formModel.userPhone.country?.name}`)
              : transl('Texts:Country')
          "
          :rules="[rules.required]"
          class="mt-4 form-field-el phone-field-el"
        />

        <!-- regions select field -->
        <v-region-field
          :label="transl('Texts:Region')"
          :rules="[rules.required]"
          :countryIso="'ua'"
          class="mt-4 form-field-el region-field-el"
          v-model:location="formModel.userRegion"
          v-if="activeAccount?.account_config_landing?.requests_form_with_regions"
        ></v-region-field>

        <v-text-field
          v-model="formModel.userLocation"
          class="mt-4 form-field-el"
          :label="transl('Texts:Location')"
        ></v-text-field>

        <div class="policy mt-2">
          <div class="d-flex">
            <div class="ml-1 mr-2 pt-1">
              <v-radio-field
                v-model="formModel.userAgreements"
                :value="true"
                :rules="[rules.required]"
                class="agreements-field-el"
              ></v-radio-field>
            </div>
            <div class="text">
              {{ transl("Landing:Agree") }}
              <span class="link" @click="emitGlobal('privacy-policy-dialog')"
                >{{ transl("Landing:With terms of use") }}
              </span>
              <span class="link" @click="emitGlobal('terms-of-use-dialog')">{{
                transl("Texts:and the policy of confidentiality")
              }}</span>
            </div>
          </div>
        </div>
      </v-form>
      <!-- form end-->

      <div class="submit-button" @click="submitForm()">
        {{ transl("Landing:Send") }}<span><img src="/icons/arrow.svg" alt="" /></span>
      </div>
    </div>
    <div class="right"><img src="/images/bible-request.png" alt="" /></div>
  </div>
</template>
<style scoped>
.request-wraper {
  max-width: 1218px;
  padding: 80px 15px;
  margin: 0 auto;
  box-sizing: border-box;
  gap: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 1023px) {
  .request-wraper {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 720px) {
  .request-wraper {
    padding: 1px 15px 60px 15px;
  }
}
.left {
  max-width: 520px;
}
.title {
  font-size: 50px;
  font-weight: 700;
  color: #202324;
}
@media (max-width: 720px) {
  .title {
    font-size: 36px;
  }
}
.description {
  color: #202324;
  font-size: 15px;
  line-height: 26px;
}
.text {
  color: #9b9b9b;
  font-size: 14px;
  line-height: 28px;
  font-family: "Montserrat";
}
.link {
  color: #1976d2;
  cursor: pointer;
  padding-left: 5px;
  text-decoration: underline;
}
.submit-button {
  color: #fff;
  background: linear-gradient(180deg, #ffb649, #e78f0c);
  border-radius: 50px;
  font-weight: 700;
  font-size: 18px;
  margin-top: 40px;
  margin-bottom: 5px;
  letter-spacing: 0.06em;
  min-height: 66px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
  text-transform: uppercase;
  cursor: pointer;
}
.submit-button img {
  padding-top: 5px;
}
.submit-button span {
  padding-left: 20px;
}
@media (hover: hover) {
  .submit-button:hover {
    background: linear-gradient(180deg, #ffcb7e, #dd9223);
  }
  .link:hover {
    opacity: 0.8;
  }
}
@media (hover: none) {
  .submit-button:active {
    background: linear-gradient(180deg, #ffcb7e, #dd9223);
  }
  .link:active {
    opacity: 0.8;
  }
}

.right {
  max-width: 530px;
  margin-left: auto;
  display: flex;
  justify-content: end;
  align-items: center;
}
@media (max-width: 1023px) {
  .right {
    display: none;
  }
}
.form-field-el :deep() {
  border-radius: 40px;
  /* border-color: #9b9b9b; */
  min-height: 80px;
  height: 80px;
}
.form-field-el :deep() input {
  font-size: 22px;
}

.phone-field-el :deep() input {
  font-weight: 100;
  font-size: 22px;
}

.phone-field-el :deep() .code-button {
  font-weight: 400;
  font-size: 24px;
}

.region-field-el {
  font-size: 24px;
}
.agreements-field-el :deep() .error-el{
  margin-top: 10px;
  /* border: 1px solid #ff5252 !important; */
}

.d-flex {
  display: flex;
}

.tabs-el :deep(.slider) {
  background-color: var(--main-bg-color, #ed9819);
}
.tab-el {
  width: 50%;
  height: 50px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-el.active {
  /* background-color: var(--button-bg); */
  color: var(--text-color);
  font-weight: 600;
}
.tabs-labels-el,
.tabs-el {
  width: 100%;
}
</style>
